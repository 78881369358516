const recordPlayer = document.querySelector("record-player");

let playBtn = document.getElementById("playPause");
playBtn.addEventListener("click", function(e)  {
	e.preventDefault();

	if(!recordPlayer.dataset.state) {
		recordPlayer.dataset.state = "PAUSED";
	}

	if(recordPlayer.dataset.state === "PAUSED") {
		recordPlayer.dataset.state = "PLAYING";
		this.textContent = "PAUSE";
		play();
	}
	else {
		recordPlayer.dataset.state = "PAUSED";
		this.textContent = "PLAY";
		pause();
	}
});

let lt = 0;
let turn = 0;
let animFrame = null;
let pauseTime = 0;

let soundSource = null;
let soundCache = {};
let audioContext = new AudioContext();
let analyser = null;
let bufferLength = 0;
let dataArray = null;
let animFrameVU;

function loadTrack(mp3, success, err) {
	let request = new XMLHttpRequest();
	request.open('GET', mp3)
	request.responseType = 'arraybuffer'
	request.onload = function() {
		audioContext.decodeAudioData(request.response, function(buffer) {
			soundCache[mp3] = buffer;
			(success || (function(){
				playBtn.disabled = false;
			}))()
		}, err || function(msg) {console.error(msg)});
	}
	request.send();
}
function playTrack(mp3, param) {
	param = param || {}
	let s = soundCache[mp3]
	soundSource = audioContext.createBufferSource();
	soundSource.buffer = s
	if (param.loop) {
		soundSource.loop = true
	}
	soundSource.connect(audioContext.destination);
	soundSource.start(0, pauseTime);
	window.ss = soundSource;
}
loadTrack("/data/music/g105b/Everlong.mp3");

function play() {
	playLoop();

	if(!soundSource) {
		playTrack("/data/music/g105b/Everlong.mp3");
	}
	else {
		softStartSound();
	}

	startVU();
}

function pause() {
	if(animFrame) {
		cancelAnimationFrame(animFrame);
		animFrame = null;
	}
	pauseTime = audioContext.currentTime;
	softStopSound();
}

function softStartSound() {
	console.log("soft start");

	let t = 0;
	for(let i = 0; i < 1; i += 0.001) {
		setTimeout(() => {
			soundSource.playbackRate.value = i;
		}, t);
		t += 0.1;
	}
}

function softStopSound() {
	let t = 0;
	for(let i = 1; i > 0; i -= 0.001) {
		setTimeout(() => {
			soundSource.playbackRate.value = i;
		}, t);
		t += 0.1;
	}

	setTimeout(() => {
		cancelAnimationFrame(animFrameVU);
		recordPlayer.querySelectorAll("#bubbles div").forEach(el => {
			el.style.scale = null;
		});
	}, t);
}

function playLoop(t) {
	let dt = t - lt;
	lt = t;
	spin(dt);
	animFrame = requestAnimationFrame(playLoop);
}

function spin(dt) {
	if(!dt) {
		dt = 0;
	}
	turn += 0.0001 * dt;
	recordPlayer.querySelectorAll("#surface,#centre").forEach(el => {
		el.style.rotate = `${turn}turn`;
	});
}

function startVU() {
	analyser = audioContext.createAnalyser();
	analyser.fftSize = 256;
	soundSource.connect(analyser);
	bufferLength = analyser.frequencyBinCount;
	dataArray = new Uint8Array(bufferLength);

	loopVU();
}

function loopVU() {
	analyser.getByteFrequencyData(dataArray);

	let low = 0;
	let medium = 0;
	let high = 0;

	let length = dataArray.length;
	for(let i = 0; i < length; i++) {
		let v = dataArray[i] / 128.0;

		if(i < length / 3) {
			low += v;
		}
		else if(i < (length / 3) * 2) {
			medium += v;
		}
		else {
			high += v;
		}
	}

	// low = 0;
	// medium = 0;
	// high = 0;
	low = (low / length) + 1.8;
	medium = (medium / length) + 1.9;
	high = (high / length) + 2.0;

	recordPlayer.querySelector("#low-bubble").style.scale = `${low}`;
	recordPlayer.querySelector("#mid-bubble").style.scale = `${medium}`;
	recordPlayer.querySelector("#high-bubble").style.scale = `${high}`;

	animFrameVU = requestAnimationFrame(loopVU);
}
